// @flow
import React from "react";
import moment from "moment";
import { Link } from "gatsby";
import type { Edges } from "../../types";
import styles from "./Feed.module.scss";

type Props = {
  edges: Edges,
};

const Feed = ({ edges }: Props) => {
  const LinkDirect = ({ children, isExternal, url, className = "" }) =>
    isExternal ? (
      <a href={url} className={className}>
        {children}
      </a>
    ) : (
      <Link to={url} className={className}>
        {children}
      </Link>
    );

  return (
    <div className={styles["feed"]}>
      {edges.map((edge) => (
        <div className={styles["feed__item"]} key={edge.node.fields.slug}>
          <div className={styles["feed__item-meta"]}>
            <div className={styles["feed__item-coverimage"]}>
              {edge.node.frontmatter.coverimage && (
                <LinkDirect
                  isExternal={edge.node.frontmatter.redirect}
                  url={
                    edge.node.frontmatter.redirect
                      ? edge.node.frontmatter.redirect
                      : "/" + edge.node.fields.slug
                  }
                >
                  <img
                    src={edge.node.frontmatter.coverimage}
                    alt={edge.node.fields.slug}
                  />
                </LinkDirect>
              )}
            </div>
            <time
              className={styles["feed__item-meta-time"]}
              dateTime={moment(edge.node.frontmatter.date).format(
                "MMMM D, YYYY"
              )}
            >
              {moment(edge.node.frontmatter.date).format("MMMM YYYY")}
            </time>
            <span className={styles["feed__item-meta-divider"]} />
            <span className={styles["feed__item-meta-category"]}>
              <Link
                to={edge.node.fields.categorySlug}
                className={styles["feed__item-meta-category-link"]}
              >
                {edge.node.frontmatter.category}
              </Link>
            </span>
          </div>
          <h2 className={styles["feed__item-title"]}>
            <LinkDirect
              isExternal={edge.node.frontmatter.redirect}
              url={
                edge.node.frontmatter.redirect
                  ? edge.node.frontmatter.redirect
                  : "/" + edge.node.fields.slug
              }
              className={styles["feed__item-title-link"]}
            >
              {edge.node.frontmatter.title}
            </LinkDirect>
          </h2>
          <p className={styles["feed__item-description"]}>
            {edge.node.frontmatter.description}
          </p>
          <LinkDirect
            isExternal={edge.node.frontmatter.redirect}
            url={
              edge.node.frontmatter.redirect
                ? edge.node.frontmatter.redirect
                : "/" + edge.node.fields.slug
            }
          >
            <button className={styles["feed__item-readmore"]}>Read more</button>
          </LinkDirect>
        </div>
      ))}
    </div>
  );
};

export default Feed;
